@import '@fontsource/nunito/latin-400.css';

body {
  font-family: 'Nunito';
}

.vertical-divider {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgb(0 0 0 / 10%), inset 0 0.125em 0.5em rgb(0 0 0 / 15%);
}

.spinner-grow {
  animation-delay: 300ms;
}

.nav-link {
  color: #000;
}
